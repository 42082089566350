import Script from 'next/script';
import { useGoogleHandler } from '@alltrails/user-auth';
import logOneTapDisplayed from '@alltrails/analytics/events/logOneTapDisplayed';
import { useSetUser, useUser } from '@alltrails/context';
import { useEffect, useState } from 'react';
import { isAndroid } from '@alltrails/core';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { useExperiments, useExposureEvent } from '@alltrails/experiments';
import { InstallBannerState } from '@alltrails/install-prompt/slices/installBanner';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import styles from './GoogleOneTap.module.scss';

const DynamicPostAuthUpsell = dynamic(() => import('@alltrails/upsell').then(module => module.PostAuthUpsellModal), { ssr: false });

type Props = {
  pageViewLimitReached?: boolean;
};

export default function GoogleOneTap({ pageViewLimitReached }: Props) {
  const googleHandler = useGoogleHandler();
  const setUser = useSetUser();
  const user = useUser();
  const isMobile = useIsMobileSizedScreen();
  const experiments = useExperiments();
  const postAuthUpsellXP = experiments?.['web-growth-rm-post-auth-upsell-one-tap'];
  const postAuthUpsellMobileXP = experiments?.['web-growth-rm-post-auth-upsell-one-tap-v2'];
  const sendPostAuthUpsellExposure = useExposureEvent('web-growth-rm-post-auth-upsell-one-tap');
  const sendPostAuthUpsellMobileExposure = useExposureEvent('web-growth-rm-post-auth-upsell-one-tap-v2');
  const [isMounted, setIsMounted] = useState(false);
  const isInstallBannerOpen = useSelector((state: { installBanner: InstallBannerState }) => state.installBanner?.isOpen);
  const [didAuthWithOneTap, setDidAuthWithOneTap] = useState(false);
  const [didShowUpsell, setDidShowUpsell] = useState(false);

  const shouldShowOneTap = isMounted && !user && (!isMobile || isAndroid());

  const [isUpsellModalOpen, setIsUpsellModalOpen] = useState(false);

  async function callback(resp: any) {
    try {
      const response = await googleHandler(resp.credential, 'login', undefined, true);
      setUser(response.user);

      if (isMobile) {
        sendPostAuthUpsellMobileExposure();
      } else {
        sendPostAuthUpsellExposure();
      }

      if (!isMobile && !response.user.pro && postAuthUpsellXP?.value === 'treatment') {
        setIsUpsellModalOpen(true);
      }
      setDidAuthWithOneTap(true);
      window.google?.accounts.id.cancel(); // google can be undefined on mobile web
    } catch (e) {
      // we don't have a design spec for showing errors so we ignore them for now
    }
  }

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (postAuthUpsellMobileXP?.value?.includes('treatment')) {
      // Display upsell after dismissing the install banner
      if (isMobile && !isInstallBannerOpen && didAuthWithOneTap && !didShowUpsell) {
        setIsUpsellModalOpen(true);
        setDidShowUpsell(true);
      }
    }
  }, [isInstallBannerOpen, didAuthWithOneTap, didShowUpsell, isMobile, postAuthUpsellMobileXP?.value]);

  return (
    <>
      {shouldShowOneTap && (
        <>
          <div id="g_one_tap" className={styles.one} />
          <Script
            async
            onLoad={() => {
              window.google.accounts.id.initialize({
                callback,
                cancel_on_tap_outside: false,
                client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID,
                prompt_parent_id: 'g_one_tap',
                use_fedcm_for_prompt: true
              });

              if (!pageViewLimitReached) {
                window.google.accounts.id.prompt((notification: any) => {
                  if (notification.isDisplayMoment() && notification.isDisplayed()) {
                    logOneTapDisplayed();
                  }
                });
              }
            }}
            src="https://accounts.google.com/gsi/client"
          />
        </>
      )}
      {isMounted && (postAuthUpsellXP?.value === 'treatment' || postAuthUpsellMobileXP?.value?.includes('treatment')) && (
        <DynamicPostAuthUpsell
          isOpen={isUpsellModalOpen}
          onCloseRequest={() => {
            setIsUpsellModalOpen(false);
          }}
        />
      )}
    </>
  );
}
