import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import styles from './styles/styles.module.scss';

export type VerticalSlideProps = {
  id?: string;
  children: ReactElement[] | ReactElement | string;
  className?: string;
  ignoreInitialOpeningAnimation?: boolean; // Used to have something start "open" without sliding open
  isOpen: boolean;
  testId?: string;
};

export default function VerticalSlide({ id, children, className, ignoreInitialOpeningAnimation, isOpen, testId }: VerticalSlideProps) {
  const [hasRendered, setHasRendered] = useState(false);
  const [isTabbable, setIsTabbable] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);

  const innerContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!innerContainer.current) {
      return;
    }

    const resizeObserver = new ResizeObserver(entry => {
      const { height } = entry[0].target.getBoundingClientRect();
      setMaxHeight(height);
    });

    resizeObserver.observe(innerContainer.current);

    return () => resizeObserver.disconnect();
  }, []);

  const style = useMemo(() => {
    const innerHeight = isOpen && innerContainer.current ? maxHeight : 0;
    if (isOpen) {
      setIsTabbable(isOpen);

      if (ignoreInitialOpeningAnimation && !hasRendered) {
        return {};
      }
    }

    setHasRendered(true);

    return { maxHeight: innerHeight };

    // the first and only update to hasRendered doesn't need to trigger this again
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ignoreInitialOpeningAnimation, isOpen, maxHeight]);

  const handleTransitionEnd = () => {
    setIsTabbable(isOpen);
  };

  return (
    <div
      id={id}
      hidden={!isTabbable}
      style={style}
      className={classNames(styles.container, { [styles.visible]: isTabbable }, className)}
      onTransitionEnd={handleTransitionEnd}
      data-testid={testId}
    >
      <div ref={innerContainer}>{children}</div>
    </div>
  );
}
