import logGearFooterClicked from '@alltrails/analytics/events/logGearFooterClicked';
import logGiveProFooterClicked from '@alltrails/analytics/events/logGiveProFooterClicked';
import logSupportFooterClicked from '@alltrails/analytics/events/logSupportFooterClicked';
import logWebGlobalFooterCommunityClicked from '@alltrails/analytics/events/logWebGlobalFooterCommunityClicked';
import LOCALIZED_PATHS from '@alltrails/shared/utils/constants/localizedPaths';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import getHelpCenterUrl from '@alltrails/shared/utils/constants/helpCenterUrl';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { type IntlShape, defineMessages } from 'react-intl';
import User from '@alltrails/shared/types/User';

export type FooterLinksType = {
  href: string;
  title: string;
  logAmplitudeEvent?: () => void;
  testId: string;
  childLinks: {
    href: string;
    logAmplitudeEvent?: () => void;
    openInNewWindow?: boolean;
    title: string;
    testId: string;
  }[];
};

const titles = defineMessages({
  MAPS: {
    defaultMessage: 'Maps'
  },
  EXPLORE: {
    defaultMessage: 'Explore'
  },
  COUNTRIES: {
    defaultMessage: 'Countries'
  },
  REGIONS: {
    defaultMessage: 'Regions'
  },
  CITIES: {
    defaultMessage: 'Cities'
  },
  PARKS: {
    defaultMessage: 'Parks'
  },
  TRAILS: {
    defaultMessage: 'Trails'
  },
  TRAIL_FEATURES: {
    defaultMessage: 'Trail Features'
  },
  POI: {
    defaultMessage: 'Points of Interest'
  },
  CREATE_MAP: {
    defaultMessage: 'Create map'
  },
  MY_MAPS: {
    defaultMessage: 'My maps'
  },
  PRINT_MAPS: {
    defaultMessage: 'Print maps'
  },
  ROUTE_CONVERTER: {
    defaultMessage: 'Route Converter'
  },
  COMPANY: {
    defaultMessage: 'Company'
  },
  ABOUT: {
    defaultMessage: 'About'
  },
  JOBS: {
    defaultMessage: 'Jobs'
  },
  PRESS: {
    defaultMessage: 'Press'
  },
  AMBASSADORS: {
    defaultMessage: 'Ambassadors'
  },
  AFFILIATES: {
    defaultMessage: 'Affiliates'
  },
  INFLUENCERS: {
    defaultMessage: 'Influencers'
  },
  COMMUNITY: {
    defaultMessage: 'Community'
  },
  SUPPORT: {
    defaultMessage: 'Support'
  },
  GIVE_ALLTRAILS_PLUS: {
    defaultMessage: 'Give AllTrails+'
  },
  ALLTRAILS_GEAR: {
    defaultMessage: 'AllTrails Gear'
  }
});

type TranslateRouteCategory = 'trails' | 'parks' | 'cities' | 'regions';

const translateRoute = (languageRegionCode: LanguageRegionCode, category: TranslateRouteCategory): string => {
  const routeMap = LOCALIZED_PATHS[languageRegionCode];
  return (routeMap && routeMap[category]) || category;
};

const signupPath = (languageRegionCode: LanguageRegionCode, ref: string) => wrapUrlSafe(`/signup?ref=${ref}`, languageRegionCode);

const proPath = (languageRegionCode: LanguageRegionCode, ref: string) => wrapUrlSafe(`/plus?ref=${ref}`, languageRegionCode);

const exploreLinks = (intl: IntlShape, languageRegionCode: LanguageRegionCode = LanguageRegionCode.English): FooterLinksType => ({
  title: intl.formatMessage(titles.EXPLORE),
  href: wrapUrlSafe('/explore', languageRegionCode),
  testId: titles.EXPLORE.defaultMessage,
  childLinks: [
    {
      title: intl.formatMessage(titles.COUNTRIES),
      href: wrapUrlSafe('/directory/countries', languageRegionCode),
      testId: titles.COUNTRIES.defaultMessage
    },
    {
      title: intl.formatMessage(titles.REGIONS),
      href: wrapUrlSafe(`/directory/${translateRoute(languageRegionCode, 'regions')}/A/1`, languageRegionCode),
      testId: titles.REGIONS.defaultMessage
    },
    {
      title: intl.formatMessage(titles.CITIES),
      href: wrapUrlSafe(`/directory/${translateRoute(languageRegionCode, 'cities')}/A/1`, languageRegionCode),
      testId: titles.CITIES.defaultMessage
    },
    {
      title: intl.formatMessage(titles.PARKS),
      href: wrapUrlSafe(`/directory/${translateRoute(languageRegionCode, 'parks')}/A/1`, languageRegionCode),
      testId: titles.PARKS.defaultMessage
    },
    {
      title: intl.formatMessage(titles.TRAILS),
      href: wrapUrlSafe(`/directory/${translateRoute(languageRegionCode, 'trails')}/A/1`, languageRegionCode),
      testId: titles.TRAILS.defaultMessage
    },
    {
      title: intl.formatMessage(titles.POI),
      href: wrapUrlSafe(`/directory/poi/A/1`, languageRegionCode),
      testId: titles.POI.defaultMessage
    },
    {
      title: intl.formatMessage(titles.TRAIL_FEATURES),
      href: wrapUrlSafe(`/directory/trail-features`, languageRegionCode),
      testId: titles.TRAIL_FEATURES.defaultMessage
    }
  ]
});

const mapLinks = (languageRegionCode: LanguageRegionCode, proUser: boolean, intl: IntlShape, currentUser?: User | null): FooterLinksType => ({
  title: intl.formatMessage(titles.MAPS),
  href: wrapUrlSafe('/plus?ref=footer', languageRegionCode),
  testId: titles.MAPS.defaultMessage,
  childLinks: [
    {
      title: intl.formatMessage(titles.MY_MAPS),
      href: currentUser ? wrapUrlSafe(`/explore/${currentUser.slug}/maps`, languageRegionCode) : signupPath(languageRegionCode, 'footer-mymaps'),
      testId: titles.MY_MAPS.defaultMessage
    },
    {
      title: intl.formatMessage(titles.CREATE_MAP),
      href: wrapUrlSafe('/explore/map/new', languageRegionCode),
      testId: titles.CREATE_MAP.defaultMessage
    },
    {
      title: intl.formatMessage(titles.PRINT_MAPS),
      href: proUser ? wrapUrlSafe('/maps/print', languageRegionCode) : proPath(languageRegionCode, 'footer-pro-menu-print'),
      testId: titles.PRINT_MAPS.defaultMessage
    },
    {
      title: intl.formatMessage(titles.ROUTE_CONVERTER),
      href: wrapUrlSafe('/converter', languageRegionCode),
      testId: titles.ROUTE_CONVERTER.defaultMessage
    }
  ]
});

const companyLinks = (languageRegionCode: LanguageRegionCode, intl: IntlShape): FooterLinksType => ({
  href: wrapUrlSafe('/about', languageRegionCode),
  title: intl.formatMessage(titles.COMPANY),
  testId: titles.COMPANY.defaultMessage,
  childLinks: [
    {
      href: wrapUrlSafe('/about', languageRegionCode),
      title: intl.formatMessage(titles.ABOUT),
      testId: titles.ABOUT.defaultMessage
    },
    {
      href: wrapUrlSafe('/careers', languageRegionCode),
      title: intl.formatMessage(titles.JOBS),
      testId: titles.JOBS.defaultMessage
    },
    {
      href: wrapUrlSafe('/press', languageRegionCode),
      title: intl.formatMessage(titles.PRESS),
      testId: titles.PRESS.defaultMessage
    },
    {
      href: wrapUrlSafe('/ambassadors', languageRegionCode),
      title: intl.formatMessage(titles.AMBASSADORS),
      testId: titles.AMBASSADORS.defaultMessage
    },
    {
      href: wrapUrlSafe('/affiliates', languageRegionCode),
      title: intl.formatMessage(titles.AFFILIATES),
      testId: titles.AFFILIATES.defaultMessage
    },
    {
      href: 'https://alltrails-connect.creatoriq.com/connect#welcome',
      title: intl.formatMessage(titles.INFLUENCERS),
      testId: titles.INFLUENCERS.defaultMessage
    }
  ]
});

const communityLinks = (languageRegionCode: LanguageRegionCode, intl: IntlShape, irclickid?: string): FooterLinksType => ({
  href: wrapUrlSafe('/community', languageRegionCode),
  title: intl.formatMessage(titles.COMMUNITY),
  logAmplitudeEvent: logWebGlobalFooterCommunityClicked,
  testId: titles.COMMUNITY.defaultMessage,
  childLinks: [
    {
      href: getHelpCenterUrl(languageRegionCode),
      logAmplitudeEvent: logSupportFooterClicked,
      title: intl.formatMessage(titles.SUPPORT),
      testId: titles.SUPPORT.defaultMessage
    },
    {
      logAmplitudeEvent: logGiveProFooterClicked,
      href: wrapUrlSafe('/gift', languageRegionCode),
      title: intl.formatMessage(titles.GIVE_ALLTRAILS_PLUS),
      testId: titles.GIVE_ALLTRAILS_PLUS.defaultMessage
    },
    {
      logAmplitudeEvent: logGearFooterClicked,
      href: `https://shop.alltrails.com/${irclickid ? `?${new URLSearchParams({ irclickid })}` : ''}`,
      openInNewWindow: true,
      title: intl.formatMessage(titles.ALLTRAILS_GEAR),
      testId: titles.ALLTRAILS_GEAR.defaultMessage
    }
  ]
});

const footerLinks = (intl: IntlShape, languageRegionCode: LanguageRegionCode, user?: User | null, irclickid?: string): FooterLinksType[] => [
  exploreLinks(intl, languageRegionCode),
  mapLinks(languageRegionCode, Boolean(user?.pro), intl, user),
  companyLinks(languageRegionCode, intl),
  communityLinks(languageRegionCode, intl, irclickid)
];

export default footerLinks;
