'use client';

import { useIntl } from 'react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { User } from '@alltrails/context';
import footerLinks, { FooterLinksType } from '../generateFooterLinks';
import styles from './styles.module.scss';
import MobileLinks from './MobileLinks';
import DesktopLinks from './DesktopLinks';

type Props = {
  languageRegionCode: LanguageRegionCode;
  user?: User | null;
  irclickid?: string;
};

const FooterLinks = ({ languageRegionCode, user, irclickid }: Props) => {
  const intl = useIntl();
  const linkData = footerLinks(intl, languageRegionCode, user, irclickid);

  return (
    <nav className={styles.nav}>
      <div className={styles.expandableContainer}>
        {linkData.map((link: FooterLinksType) => (
          <MobileLinks key={link.title} href={link.href} childLinks={link.childLinks} title={link.title} testId={link.testId} />
        ))}
      </div>
      <div className={styles.footerLinksContainer}>
        {linkData.map((link: FooterLinksType) => (
          <DesktopLinks key={link.title} href={link.href} childLinks={link.childLinks} title={link.title} testId={link.testId} />
        ))}
      </div>
    </nav>
  );
};

export default FooterLinks;
