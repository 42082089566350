import Expandable from '@alltrails/shared/components/Expandable';
import { COLOR_TEXT_PRIMARY_INVERSE } from '@alltrails/denali/tokens';
import { FooterLinksType } from '../../generateFooterLinks';
import styles from './styles.module.scss';

const ExpandableFooterLink = ({ href, title, logAmplitudeEvent, childLinks = [] }: FooterLinksType): JSX.Element => (
  <Expandable
    iconColor={COLOR_TEXT_PRIMARY_INVERSE}
    title={
      <a data-testid={title} className={styles.titleLink} href={href} onClick={logAmplitudeEvent}>
        {title}
      </a>
    }
  >
    <ul>
      {childLinks.map(link => (
        <li key={link.title} className={styles.listItem}>
          <a
            data-testid={link.title}
            href={link.href}
            onClick={() => {
              if (link.logAmplitudeEvent) {
                link.logAmplitudeEvent();
              }
            }}
          >
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  </Expandable>
);

export default ExpandableFooterLink;
