'use client';

import Link from 'next/link';
import { wrapUrlSafe } from '@alltrails/shared/utils/languageSupport';
import { useLanguageRegionCode } from '@alltrails/language';
import Logo from '@alltrails/denali/components/Logo';
import useIsMobileSizedScreen from '@alltrails/denali/hooks/useIsMobileSizedScreen';
import { useUser } from '@alltrails/context';
import logWebHeaderAllTrailsLogoTapped from '@alltrails/analytics/events/logWebHeaderAllTrailsLogoTapped';
import styles from './styles/styles.module.scss';
import { HeaderBackground } from './types';

type LogoLinkProps = {
  background?: HeaderBackground;
};

const LogoLink = ({ background }: LogoLinkProps) => {
  const languageRegionCode = useLanguageRegionCode();
  const isMobile = useIsMobileSizedScreen();
  const user = useUser();

  return (
    <div className={styles.focusContainer}>
      <Link
        className={styles.logoLink}
        data-testid="header-logo"
        href={wrapUrlSafe('/?ref=header', languageRegionCode)}
        onClick={() => {
          logWebHeaderAllTrailsLogoTapped();
        }}
      >
        <Logo color={background === 'transparent-inverted' ? 'light' : 'dark'} size="sm" variant={user || isMobile ? 'symbol' : 'logomark'} />
      </Link>
    </div>
  );
};
export default LogoLink;
