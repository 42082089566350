import { type FooterLinksType } from '../../generateFooterLinks';
import styles from './styles.module.scss';

const DesktopLinks = ({ href, title, testId, logAmplitudeEvent, childLinks = [] }: FooterLinksType): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.title}>
      <a href={href} data-testid={testId} onClick={logAmplitudeEvent}>
        {title}
      </a>
    </div>
    <ul>
      {childLinks.map(link => (
        <li key={link.title} className={styles.childLink}>
          <a
            data-testid={link.testId}
            href={link.href}
            {...(link.openInNewWindow ? { target: '_blank' } : {})}
            onClick={() => {
              if (link.logAmplitudeEvent) {
                link.logAmplitudeEvent();
              }
            }}
          >
            {link.title}
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default DesktopLinks;
