'use client';

import useOsanoAnalytics from '@alltrails/layout/hooks/useOsanoTracking';
import useBraze from '@alltrails/layout/hooks/useBraze';
import useTinuitiPixel from '@alltrails/layout/hooks/useTinuitiPixel';
import AmplitudeBrowserSdk from '@alltrails/layout/scripts/AmplitudeBrowserSdk';
import GoogleOneTap from '@alltrails/layout/components/GoogleOneTap';

const LocationPageAnalytics = () => {
  useOsanoAnalytics();
  useTinuitiPixel();
  useBraze();

  return (
    <>
      <AmplitudeBrowserSdk />
      <GoogleOneTap pageViewLimitReached={false} />
    </>
  );
};

export default LocationPageAnalytics;
